<template>
    <div class="search_log_list">
        <el-card>
            <nav class="out">
                <nav class="input">
                    <el-date-picker
                        v-model="search.createTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        style="width: 100%"
                        size="medium"
                    />
                </nav>
                <nav class="input" style="width: 123px; margin-top: 2px">
                    <el-button size="small" @click="reset">重置</el-button>
                    <el-button type="primary" size="small" @click="subimt">{{
                        "搜索"
                    }}</el-button>
                </nav>
                <nav class="input" style="width: auto; margin-top: 2px">
                    <el-button
                        :loading="loading"
                        size="small"
                        type="primary"
                        @click="derive"
                    >
                        导出
                    </el-button>
                </nav>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px;">
            <el-table
                :data="list"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
            >
                <el-table-column label="Id" prop="id"></el-table-column>
                <el-table-column label="类型" prop="type"></el-table-column>
                <el-table-column label="ip" prop="ip"></el-table-column>
                <el-table-column label="渠道" prop="terminal"></el-table-column>
                <el-table-column label="会员ID" prop="userId"></el-table-column>
                <el-table-column
                    label="关键词"
                    prop="keyword"
                ></el-table-column>
                <el-table-column
                    label="创建时间"
                    prop="createTime"
                ></el-table-column>
            </el-table>
            <div-pagination
                :total="total"
                :search="search"
                @currentChange="currentChange"
            ></div-pagination>
        </el-card>
    </div>
</template>

<script>
// import loadingButtonVue from './loading-button.vue'
import list from "/src/mixin/list.js";

export default {
    mixins: [list],

    data() {
        return {
            list: [],
            search: {
                page: 1,
                pageSize: 10,
                createTime: [],
            },
            loading: false,
            total: 0,
        };
    },
    created() {
        this.getList();
    },
    methods: {
        async getList() {
            let { data: res } = await this.$http.get(
                "/admin/SysConfig/getSearchLog",
                {
                    params: {
                        ...this.search,
                        createTime: this.search.createTime.join("~"),
                    },
                }
            );
            this.list = res.data.list;
            this.total = res.data.totalCount || 0;
        },
        currentChange(e) {
            this.search.page = e;
            this.getList();
        },
        async derive() {
            this.loading = true;
            let { data: res } = await this.$http.post(
                "/admin/SysConfig/exportSearchLog",
                {
                    ...this.search,
                    createTime: this.search.createTime.join("~"),
                }
            );
            this.loading = false;
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                location.href = res.data.url;
            }
        },
        reset() {
            this.search = this.$options.data.call(this).search;
            this.getList();
        },
        subimt() {
            this.search.page = 1;
            this.getList();
        },
    },
};
</script>

<style lang="less" scoped></style>
